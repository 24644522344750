(function ($, window, document) {
    var $win = $(window);
    var $doc = $(document);
    var $breakpoints = {
        mobileSmall: 420,
        mobileMedium: 575,
        mobile: 767,
        adminBar: 782,
        tablet: 1023,
        smallDesktop: 1199,
        largeDesktop: 1399,
    };

    var $isMobile = false;

    if ($win.innerWidth() <= 767) {
        $isMobile = true;
    }
    function window_width() {
        var myWidth = 0;
        if (typeof window.innerWidth == "number") myWidth = window.innerWidth;
        else if (
            document.documentElement &&
            document.documentElement.clientWidth
        )
            myWidth = document.documentElement.clientWidth;
        else if (document.body && document.body.clientWidth)
            myWidth = document.body.clientWidth;
        return myWidth;
    }

    function window_height() {
        var myHeight = 0;
        if (typeof window.innerHeight == "number")
            myHeight = window.innerHeight;
        else if (
            document.documentElement &&
            document.documentElement.clientHeight
        )
            myHeight = document.documentElement.clientHeight;
        else if (document.body && document.body.clientHeight)
            myHeight = document.body.clientHeight;
        return myHeight;
    }

    var swiper = new Swiper(".hero-swiper", {
        spaceBetween: 30,
        effect: "fade",
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });

    /**
     * Countdown
     */
    (function () {
        const second = 1000,
            minute = second * 60,
            hour = minute * 60,
            day = hour * 24;

        //I'm adding this section so I don't have to keep updating this pen every year :-)
        //remove this if you don't need it
        let today = new Date(),
            dd = String(today.getDate()).padStart(2, "0"),
            mm = String(today.getMonth() + 1).padStart(2, "0"),
            yyyy = today.getFullYear(),
            nextYear = yyyy + 1,
            monthDay = "01/19/",
            birthday = monthDay + yyyy;

        today = mm + "/" + dd + "/" + yyyy;
        if (today > birthday) {
            birthday = monthDay + nextYear;
        }
        //end

        const countDown = new Date(birthday).getTime(),
            x = setInterval(function () {
                const now = new Date().getTime(),
                    distance = countDown - now;

                (document.getElementById("days").innerText = Math.floor(
                    distance / day
                )),
                    (document.getElementById("hours").innerText = Math.floor(
                        (distance % day) / hour
                    )),
                    (document.getElementById("minutes").innerText = Math.floor(
                        (distance % hour) / minute
                    )),
                    (document.getElementById("seconds").innerText = Math.floor(
                        (distance % minute) / second
                    ));

                //do something later when date is reached
                if (distance < 0) {
                    document.getElementById("headline").innerText =
                        "It's my birthday!";
                    document.getElementById("countdown").style.display = "none";
                    document.getElementById("content").style.display = "block";
                    clearInterval(x);
                }
                //seconds
            }, 0);
    })();

    $(".js-gallery").magnificPopup({
        delegate: "a",
        type: "image",
        tLoading: "Loading image #%curr%...",
        mainClass: "mfp-img-mobile",
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function (item) {
                return item.el.attr("title") + "<small>Kim & Piolo</small>";
            },
        },
    });

    $(".js-swiper").magnificPopup({
        delegate: "a",
        type: "image",
        tLoading: "Loading image #%curr%...",
        mainClass: "mfp-img-mobile",
        preloadImages: false,

        lazy: true,

        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function (item) {
                return item.el.attr("title") + "<small>Kim & Piolo</small>";
            },
        },
    });

    $(".js-image-popup").magnificPopup({
        type: "image",
        closeOnContentClick: true,
        image: {
            verticalFit: false,
        },
    });

    $(".js-info").on("click", function (e) {
        e.preventDefault();
        $("body").toggleClass("no-scroll");
        let $this = $(this);
        let $popupType = $this.data("type");
        let $target = $this.data("href");
        let $popup = $($target);
        let $modal = $popup.find(".popup__content");
        $popup.toggleClass("popup-active");
    });

    $(".close-popup").on("click", function () {
        $(this).parent().toggleClass("popup-active");
        $("body").toggleClass("no-scroll");
    });

    $(".confirmar-js").magnificPopup({
        type: "inline",
        midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
    });

    $win.on("load", () => {
        var swiper = new Swiper(".memoriesSwiper", {
            // effect: "cards",
            grabCursor: true,
        });
    });

    $win.on("scroll", function () {
        if ($(window).scrollTop()) {
            $(".navbar").addClass("on-scroll");
        } else {
            $(".navbar").removeClass("on-scroll");
        }
    });

    /*menu button onclick function*/
    $doc.ready(function () {
        $(".menu .toggle").click(function () {
            $("nav ul").toggleClass("active");
        });

        $(".menu-items a").click(function () {
            if (window_width() <= $breakpoints.tablet) {
                $(".menu-items").toggleClass("active");
            }
        });
    });

    function initHorizontalSlider() {
        const paralaxGalleryContainer = document.querySelector(".js-gallery");
        const galleryContainer = document.querySelector(".horizontal-gallery ");
        const parallaxConfig = {
            rootMargin: "-100px 0px",
            threshold: 0.25,
        };
        if ($isMobile) {
            return true;
        }

        const scrollGalleries =
            paralaxGalleryContainer.querySelectorAll(".scroll__wrapper");

        const parallaxCallback = (entries) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) {
                    paralaxGalleryContainer.classList.remove("active");
                    galleryContainer.classList.remove("forced-top");
                    // page.classList.remove("scroll-container");

                    // snapSection.forEach((entry) => {
                    //     entry.classList.remove("snap-section");
                    // });
                } else {
                    paralaxGalleryContainer.classList.add("active");
                    galleryContainer.scrollIntoView({ behavior: "smooth" });
                }
            });
        };

        const galleryObserver = new IntersectionObserver(
            parallaxCallback,
            parallaxConfig
        );

        scrollGalleries.forEach((element) => {
            galleryObserver.observe(element);
        });
    }

    /**
     * Animate Counters
     */
    function initCounters() {
        function animateCounters() {
            const counters = document.querySelectorAll(".js-count");
            let speed = 500;

            counters.forEach((counter, index) => {
                let incrementalSpeed = speed * (index + 1);
                const animate = () => {
                    const value = +counter.getAttribute("data-count");
                    const data = +counter.innerText;

                    const time = value / incrementalSpeed;
                    if (data < value) {
                        counter.innerText = Math.ceil(data + time);
                        setTimeout(animate, 1);
                    } else {
                        counter.innerText = value;
                    }
                };

                animate();
            });
        }

        const jsCounters = document.querySelectorAll(".js-count");
        const configCounters = {
            rootMargin: "0px",
            threshold: 0,
        };
        const callbackCounters = (entries) => {
            entries.forEach((entry, index) => {
                let speed = 500;
                let incrementalSpeed = speed * (index + 1);
                if (!entry.isIntersecting) {
                } else {
                    animateCounters();
                }
            });
        };

        const observer = new IntersectionObserver(
            callbackCounters,
            configCounters
        );

        jsCounters.forEach((element) => {
            observer.observe(element);
        });
    }

    $win.on("load", function () {
        initHorizontalSlider();
        initCounters();
    });
})(jQuery, window, document);
